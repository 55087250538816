<template>
    <app-header/>
  <Suspense >
    <template #default>
        <app-category :id="id"/>
    </template>
    <template #fallback>
        <loding/>
    </template>

  </Suspense>
  <app-footer/>
</template>

<script>
import { defineAsyncComponent, Suspense } from "vue";
// import AppCategory from '../../components/Categorys/AppCategory.vue'
import AppFooter from '../../components/Include/AppFooter.vue'
import AppHeader from '../../components/Include/AppHeader.vue'
import Loding from '../../components/Include/Loding.vue'
const AppCategory = defineAsyncComponent(() =>
  import('@/components/Categorys/AppCategory.vue')
)
export default {
    name: 'Category',
     props:{
        id:String
     },
  components: { Suspense,AppCategory, AppHeader, Loding, AppFooter },

}
</script>

<style>

</style>